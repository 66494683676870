<template>
  <div id="member_points">
    <div class="box">
      <div class="integral_value">
        {{ integralSum }} <img src="@/assets/images/jf_icon.png" width="20" />
      </div>
      <div class="integral_list">
        <div
          class="list"
          v-for="list in bankInfo"
          :key="list.bankId"
          @click="
            $router.push({
              path: '/userData/integral',
              query: {
                bankId: list.bankId,
                name: list.bankName,
              },
            })
          "
        >
          <span class="name">{{ list.bankName }}</span>
          <div>
            <span class="value">{{ list.integral }}</span>
            <span class="detail"
              >明细<svg-icon icon-class="arrow_right"
            /></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "member-points",
  computed: {
    ...mapState("user", ["bank", "integralSum"]),
  },
  data() {
    return {
      bankInfo: [],
    };
  },

  async created() {
    await this.$store.dispatch("user/getCusBankFilePathList");
    this.bank.forEach((bank) => {
      if (bank.bankId !== "0") {
        this.bankInfo.push(bank);
      }
    });
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
#member_points {
  background: #ffc016;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 56px;
  .box {
    background: #f6f7fb;
    flex: 1;
    border-radius: 10px 10px 0px 0px;
    padding: 0 15px;

    .integral_value {
      padding: 16px 0;
      font-size: 32px;
      font-weight: 800;
      color: #333333;
    }
    .integral_list {
      .list {
        position: relative;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 12px;
        padding: 20px 15px;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        align-items: center;

        .name {
          color: #333333;
        }
        .value {
          font-weight: bold;
          color: #fb4e10;
        }
        .detail {
          padding-left: 12px;
          color: #999;
          font-size: 14px;
          .svg-icon {
            margin-left: 4px;
            font-size: 12px;
            vertical-align: -1px;
          }
        }
      }
      .list::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background-color: #000;
        border: inherit;
        border-radius: inherit;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        opacity: 0;
      }
      .list:active::before {
        opacity: 0.1;
      }
    }
  }
}
</style>
